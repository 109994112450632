import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import ContactForm from '../components/ContactForm/ContactForm';

const ViewDemo = () => {
  return (
    <Layout footer={false}>
      <Seo
        title="Request Demo | Bridge"
        description="Learn more about our patient portal for hospitals, large practices, and health systems. Contact us to see a demo."
        canonical="/view-a-demo/"
      />
      <Section variant="hero">
        <Container>
          <Row>
            <Col>
              <h1 className="text-center">REQUEST A DEMO</h1>
            </Col>
          </Row>
          <ContactForm />
        </Container>
      </Section>
    </Layout>
  );
};

export default ViewDemo;
